<template>
    <div class="m-work" v-loading="!isReady">
        <div class="m-work-hd">
            <div class="m-work-hd-left">MML Worker</div>
            <div class="m-work-hd-center">
                <el-menu mode="horizontal">
                    <el-submenu index="null">
                        <template slot="title">常用链接</template>
                        <el-submenu
                            v-for="(childList, name, index) of list1"
                            :key="index"
                            :index="name"
                        >
                            <template slot="title">{{ name }}</template>
                            <el-menu-item
                                v-for="childItem of childList"
                                class="link-meun-item"
                                :key="childItem.id"
                                ><el-link
                                    :href="childItem.link"
                                    :underline="false"
                                    :target="linkTarget(childItem.link)"
                                    >{{ childItem.link_name }}</el-link
                                ></el-menu-item
                            >
                        </el-submenu>

                        <el-menu-item
                            v-for="item of list2"
                            class="link-meun-item"
                            :key="item.id"
                        >
                            <el-link
                                :href="item.link"
                                :underline="false"
                                :target="linkTarget(item.link)"
                                >{{ item.link_name }}</el-link
                            >
                        </el-menu-item>
                    </el-submenu>
                </el-menu>
            </div>
            <div class="m-work-hd-right">
                <div v-if="userName" v-text="userName"></div>
                <div v-if="userName">
                    <el-link @click="logout">退出</el-link>
                </div>
            </div>
        </div>
        <div class="m-work-bd">
            <div class="m-work-bd-left">
                <el-menu>
                    <template v-for="(menuItem, menuItemIndex) of menu">
                        <el-submenu
                            v-if="menuItem.children"
                            :index="menuItemIndex.toString()"
                            :key="`submenu-${menuItemIndex}`"
                        >
                            <template slot="title">
                                {{ menuItem.name }}
                            </template>
                            <el-menu-item
                                v-for="(
                                    childItem, childItemIndex
                                ) of menuItem.children"
                                :index="
                                    (
                                        menuItemIndex +
                                        '-' +
                                        childItemIndex
                                    ).toString()
                                "
                                :key="menuItemIndex + '-' + childItemIndex"
                            >
                                <router-link :to="childItem.url">{{
                                    childItem.name
                                }}</router-link>
                            </el-menu-item>
                        </el-submenu>
                        <el-menu-item
                            v-else
                            :index="menuItemIndex.toString()"
                            :key="`menu-${menuItemIndex}`"
                        >
                            <router-link
                                :to="menuItem.url ? menuItem.url : '/'"
                                >{{ menuItem.name }}</router-link
                            >
                        </el-menu-item>
                    </template>
                    <!-- <el-menu-item index="work-center"
                        ><i class="el-icon-user"></i>
                        <router-link :to="{ name: 'work-center' }"
                            >个人中心</router-link
                        >
                    </el-menu-item>
                    <el-submenu index="contract">
                        <template slot="title"
                            ><i class="el-icon-s-cooperation"></i>合同</template
                        >
                        <el-menu-item index="contract-customer">
                            <router-link :to="{ name: 'contract-customer' }"
                                >客户管理</router-link
                            >
                        </el-menu-item>
                        <el-menu-item index="contract-management">
                            <router-link :to="{ name: 'contract-management' }"
                                >合同管理</router-link
                            >
                        </el-menu-item>
                    </el-submenu> -->
                </el-menu>
            </div>
            <div class="m-work-bd-right">
                <div class="m-work-bd-right-wrap">
                    <router-view v-if="isRouterAlive"></router-view>
                </div>
                <div class="m-work-bd-right-ft">
                    <div class="wrap">
                        <div class="item item1">
                            &copy; 2017 - {{ year }} MML Digital Marketing<br />All
                            rights reserved.
                        </div>
                        <div class="item item1">
                            版本: v0.5-Beta (公测版本) <br />(发布于:
                            2021-07-15)
                        </div>
                        <div class="item item2">系统维护: 技术部</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import MainLayout from '../components/main-layout.vue'
import { mapState } from "vuex";
export default {
    components: {},
    data() {
        return {
            isReady: false,
            year: new Date().getFullYear(),
            isRouterAlive: true,
            // 含分组的链接
            list1: {},
            // 不含分组的链接
            list2: {},
            menu: [],
            childItem: "",
        };
    },
    computed: {
        userName() {
            if (this.$store && this.$store.state && this.$store.state.user) {
                return this.$store.state.user.display_name;
            }
            return "";
        },
        ...mapState(["normalLinkList"]),
    },
    watch: {
        normalLinkList(newData) {
            this.list2 = newData.nogroup;
            let newObject = Object.assign({}, newData);
            delete newObject.nogroup;
            this.list1 = newObject;
        },
    },
    mounted() {
        let that = this;
        let user = false;
        that.$ajax("/account/get-auth-info", {})
            .done(function (ret) {
                if (ret.user) {
                    user = ret.user;
                }
            })
            .fail(function () {
                //
            })
            .always(function () {
                if (user) {
                    that.$store.commit("updateUser", user);
                    that.isReady = true;
                } else {
                    that.$router.push({ name: "Home" });
                }
            });

        that.$ajax("/account/get-custom-links", { type: 2 }).done((res) => {
            if (res) {
                that.$store.commit("updateNormalLinkList", res);
            }
        });
        that.$ajax("/account/get-menu").done((res) => {
            if (res) {
                that.menu = that.pathChange(res);
            }
        });
    },
    methods: {
        onMenuSelect(index, indexPath) {
            // index, indexPath
            console.log(index, indexPath);
            if (this.$router.currentRoute.name === index) {
                return;
            }
            this.$router.push({ name: index });
        },
        logout() {
            let that = this;
            that.$confirm("确定要退出当前账号吗？").then(() => {
                that.$ajax("/account/logout").done(() => {
                    that.$router.push({ path: "/" });
                });
            });
        },
        linkTarget(link) {
            return link.startsWith("#") ? "_self" : "_target";
        },
        pathChange(res) {
            res.forEach((item) => {
                if (item.children) {
                    this.pathChange(item.children);
                }
                if (item.url)
                    item.url = item.url.replace("https://worker.mmler.cn", "");
                // item.url = item.url.replace(window.location.origin, "");
            });
            return res;
        },
    },
};
</script>

<style lang="less">
.m-work {
    &-hd {
        min-width: 1024px;
        display: flex;
        color: white;

        &-left {
            width: 200px;
            background-color: #333;
            height: 52px;
            line-height: 52px;
            text-align: center;
        }

        &-center {
            flex: 1;
            padding: 10px;
            // background-color: rgb(217, 236, 255);
            background-color: #ecf5ff;
            border-bottom: 1px solid #b3d8ff;
            box-sizing: border-box;
            height: 52px;
            .el-menu {
                display: inline-block;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                // padding: 5px 0;
                .el-submenu__title {
                    height: unset;
                    line-height: unset;
                    padding: 10px 20px;
                    min-width: 200px;
                    text-align: center;
                    cursor: default;
                    // padding: 5px 20px;
                }
                &.el-menu--horizontal
                    > .el-submenu.is-active
                    .el-submenu__title {
                    border-bottom: none;
                }
                &.el-menu--horizontal
                    > .el-submenu.is-active
                    .el-submenu__title {
                    border-bottom: none;
                }
            }
        }

        &-right {
            width: 200px;
            background-color: #333;
            padding: 9px;
            height: 52px;
            box-sizing: border-box;
        }
    }
    &-bd {
        display: flex;

        &-left {
            width: 200px;

            .el-menu {
                height: 100%;
                border-right: none;
                background-color: #409eff;
            }

            .el-submenu {
                &.is-active {
                    .el-submenu__title {
                        background-color: rgb(83, 168, 255);
                    }
                }

                .el-submenu__title {
                    color: white;

                    &:hover {
                        background-color: rgb(102, 177, 255);
                    }

                    i {
                        color: white;
                    }
                }
            }

            .el-menu-item {
                color: white;

                a {
                    color: white;
                    text-decoration: none;
                }

                &.is-active {
                    color: white;
                    background-color: rgb(102, 177, 255);
                }
                &:hover {
                    background-color: rgb(102, 177, 255);
                }

                i {
                    color: white;
                }
            }
        }

        &-right {
            position: relative;
            min-width: 804px;
            flex: 1;
            padding: 10px;

            &-wrap {
                padding-bottom: 60px;
            }
            &-ft {
                padding: 10px;
                box-sizing: border-box;
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                text-align: center;
                background-color: #ecf5ff;
                border-top: 1px solid #b3d8ff;
                color: #409eff;
                font-size: 14px;

                .wrap {
                    display: flex;
                }

                .item {
                    flex: 1;
                }
            }
        }
    }
}
.el-menu-item.link-meun-item {
    cursor: default;
    a {
        display: block;
        // &.is-underline:hover::after {
        //     border-bottom: none !important;
        // }
    }
}
</style>
